.sub {
    //height: 500px;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 3px 5px 6px #ccc;  /* Firefox 3.5 - 3.6 */
    box-shadow:         3px 3px 5px 6px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  
  .sub-top {
    margin: 0;
    padding: 0;
    vertical-align: middle;
    align-content: center;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: $ka-dark-grey;
    flex-direction: column;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  
  .sub-description {
    align-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 20em;
  }

  .sub-description-v2 {
    align-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 13em;
  }
  
  .card-title {
    text-align: center;
    width: 100%;
    margin: 0;
    color: var(--light);
    font-size: 2.5em;
  }
  
  .pricing-tier {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  
  .available-tiers {
    background-color: $ka-turquoise;
    color: var(--light);
    font-size: 1em;
  }
  
  }

  .sub-mid-description {
      height: 5em;
  }

  .sub-get-started {
      color: #304215;
      font-weight: 700;
      font-size: 1em;
      width: 80%;
  }


  .alt-sub {
    .sub-top {
        background-color: $ka-dark-grey;
    }
  }

  .price-value {
      font-size: 60px;
  }

  .price-description {
      font-size: 18px;
  }


  .popular-header {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%,-100%);
    transform: translate(-50%,-100%);
    background-color: #2ac0d9;
    padding: 5px 25px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .popular-highlight {
    box-shadow: 0 0 14px 4px #2ac0d9;
  } 

  /** Redesign */

  .sub-v2 {
    -webkit-box-shadow: unset;
    -moz-box-shadow:    unset;
    box-shadow:         unset;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    background-color: #eeeeee;

    .sub-top {
      background-color: #b4a8a8;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }

  }

  #free {
    .sub-top {
      background-color: #b4a8a8;
    }

    .btn {
      border-width: 3px;
      color: $ka-turquoise;
    }
  }

  #pro {
    .sub-top {
      background-color: $ka-turquoise;
    }

    .btn {
      color: #fff;
    }

  }