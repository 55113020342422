@import "variables";
@import "mixins";

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $ka-text;
}

label {
  font-weight: 500;
}
