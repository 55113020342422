@import "variables";

.scormify-slider .ng5-slider .ng5-slider-pointer {

  background-color: $emerald !important;
}


// https://codepen.io/melnik909/pen/oewwBo/
// https://codepen.io/claviska/pen/KyWmjY


.switch {
  --uiSwitchSize: var(--switchSize, 64px);
  --uiSwitchBgColor: var(--switchBgColor, #f1f1f1);
  --uiSwitchBgColorActive: var(--switchBgColorActive, #3FC380);
  --uiSwitchBorderColorActive: var(--switchBorderColorActive, #fff);
  --uiSwitchBorderColorFocus: var(--switchBgColorFocus, #3FC380);
  --uiSwitchButtonBgColor: var(--switchButtonBgColor, #fff);

  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.switch__label {
  display: block;
  width: 100%;
  height: 100%;
}

.switch__toggle {
  width: 0;
  height: 0;
  opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
}

.switch__toggle:focus~.switch__label {
  box-shadow: 0 0 0 var(--uiSwitchThickFocus, 4px) var(--uiSwitchBorderColorFocus);
}

.switch__toggle:checked:focus~.switch__label {
  box-shadow: 0 0 0 var(--uiSwitchThickFocus, 4px) var(--uiSwitchBorderColorActive);
}

.switch__label:before,
.switch__label:after {
  content: "";
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;
}

.switch__label:before {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--uiSwitchBgColor);
}

.switch__label:after {
  top: 50%;
  z-index: 3;
  transition: transform .4s cubic-bezier(0.44, -0.12, 0.07, 1.15);
}

/* type 1 */

.switch_type1 {
  --uiSwitchBorderRadius: var(--switchBorderRadius, 60px);

  width: var(--uiSwitchSize);
  height: calc((var(--uiSwitchSize) / 2));
  border-radius: var(--uiSwitchBorderRadius);
  background-color: var(--uiSwitchBgColorActive);
}

.switch_type1 .switch__label {
  border-radius: var(--uiSwitchBorderRadius);
}

.switch_type1 .switch__label:before {
  border-radius: var(--uiSwitchBorderRadius);
  transition: opacity .2s ease-out .1s, transform .2s ease-out .1s;
  transform: scale(1);
  opacity: 1;
}

.switch_type1 .switch__toggle:checked~.switch__label:before {
  transform: scale(0);
  opacity: .7;
}

.switch_type1 .switch__label:after {
  width: calc(var(--uiSwitchSize) / 2);
  height: calc(var(--uiSwitchSize) / 2);
  transform: translate3d(0, -50%, 0);

  background-color: var(--uiSwitchButtonBgColor);
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
}

.switch_type1 .switch__toggle:checked~.switch__label:after {
  transform: translate3d(100%, -50%, 0);
}

/* type 2 */

.switch_type2 {
  --uiSwitchIndent: var(--switchIndent, 8px);
  --uiSwitchBorderRadius: var(--switchBorderRadius, 60px);

  width: var(--uiSwitchSize);
  height: calc((var(--uiSwitchSize) / 2));
  border-radius: var(--uiSwitchBorderRadius);
  background-color: var(--uiSwitchBgColorActive);
}

.switch_type2 .switch__label {
  border-radius: var(--uiSwitchBorderRadius);
}

.switch_type2 .switch__label:before {
  border-radius: var(--uiSwitchBorderRadius);
  transition: opacity .2s ease-out .1s, transform .2s ease-out .1s;
  transform: scale(1);
  opacity: 1;
}

.switch_type2 .switch__toggle:checked~.switch__label:before {
  transform: scale(0);
  opacity: .7;
}

.switch_type2 .switch__toggle~.switch__label:after {
  width: calc((var(--uiSwitchSize) / 2) - calc(var(--uiSwitchIndent) * 2));
  height: calc((var(--uiSwitchSize) / 2) - calc(var(--uiSwitchIndent) * 2));
  transform: translate3d(var(--uiSwitchIndent), -50%, 0);

  background-color: var(--uiSwitchButtonBgColor);
  border-radius: 100%;
}

.switch_type2 .switch__toggle:checked~.switch__label:after {
  transform: translate3d(calc(var(--uiSwitchSize) - calc((var(--uiSwitchSize) / 2) - calc(var(--uiSwitchIndent) * 2)) - var(--uiSwitchIndent)), -50%, 0);
}

/* type 3 */

.switch_type3 {
  --uiSwitchIndent: var(--switchIndent, 8px);

  width: var(--uiSwitchSize);
  height: calc((var(--uiSwitchSize) / 2));
  background-color: var(--uiSwitchBgColorActive);
}

.switch_type3 .switch__toggle:checked~.switch__label:before {
  background-color: var(--uiSwitchBgColorActive);
}

.switch_type3 .switch__label:after {
  width: calc((var(--uiSwitchSize) / 2) - calc(var(--uiSwitchIndent) * 2));
  height: calc((var(--uiSwitchSize) / 2) - calc(var(--uiSwitchIndent) * 2));
  transform: translate3d(var(--uiSwitchIndent), -50%, 0);
  background-color: var(--uiSwitchButtonBgColor);
}

.switch_type3 .switch__toggle:checked~.switch__label:after {
  transform: translate3d(calc(var(--uiSwitchSize) - calc((var(--uiSwitchSize) / 2) - calc(var(--uiSwitchIndent) * 2)) - var(--uiSwitchIndent)), -50%, 0);
}

/* demo styles for switch */

.switch {
  --switchSize: 42px;
  margin-top: 10px;
}

.switch_type2 {
  --switchBgColorActive: #e85c3f;
  --switchBgColorFocus: #d54b2e;
}

.resetBtn,
.afu-reset-btn {
  position: relative;
  bottom: 4px !important;
}

.afu-attach-pin {
  width: 40%;
}
