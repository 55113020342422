.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle.btn-md {
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 30px;
  text-align: center;
  font-size: 24px;
  line-height: 1.42857;
}


.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
