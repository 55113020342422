$ka-grey-blue: rgb(0, 57, 70);
$ka-green: #93c83d;
$ka-dark-grey: rgb(44, 45, 48);
$ka-cream: #f9f9fa;
$ka-turquoise: rgb(42, 192, 217);
$ka-text: #3f4146;

$emerald: #3FC380;
$monza: #CF000F;
$azul: #2ac0d9;
$grey: #ccc;

$bs-light: var(--light);


//$font-body: 'Work Sans';
//$font-header: 'Noto Sans';
//$font-serif: 'Noto Serif';

$font-header: 'Raleway';
$font-body: 'Raleway';


$header-background: $grey;
$body-background: #f8f9fa;
$validation-green: $emerald;
$validation-red: $monza;

// rnadom cool green i found: //hsl(147, 200, 61);

// ka turq #2ac0d9 #3cemediumturquoise hsl(188,69,50) rgb(42,192,217)

// ka yellow green #93c83d #9d4yellowgreen hsl(82,55,51) rgb(147,200,61)

// midnight blue #002d3d #034midnightblue hsl(195,100,11) rgb(0,45,61)


$theme-colors: (
  'azul': $azul,
  'emerald': $ka-green,
  'light-grey': $grey,
  'primary': $ka-grey-blue,
  'secondary': $azul,
  'ka-green': $ka-green,
  'ka-grey-blue': $ka-grey-blue,
  'ka-dark-grey': $ka-dark-grey,
  'ka-turquoise': $ka-turquoise,
  'ka-cream': $ka-cream,
  'red': $monza,
  'danger': #dc3545 ,
  'warning': #ffc107,
  'success': #198754
  );
