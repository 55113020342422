/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;800;900&display=swap");
@import "assets/scss/variables.scss";
@import "assets/scss/buttons.scss";
@import "assets/scss/typeography.scss";
@import "assets/scss/form-components.scss";
@import "assets/scss/loader.scss";
@import "assets/scss/animations.scss";
@import "assets/scss/inputs.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
$fa-font-path: "../node_modules/font-awesome/fonts";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "assets/scss/subs.scss";

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: $body-background;
  font-family: $font-body;
  color: $ka-dark-grey;
}

body {
  background-color: $body-background;
  font-family: $font-body !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-header;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  color: $secondary !important;
}

a:active,
a.active {
  color: pink;
}

.btn-list {
  button {
    margin-left: 15px;
    margin-bottom: 10px;
    width: 245px;

    @media screen and (max-width: 800px) {
      width: 80%;
      display: block;
    }
  }

  button:first-child {
    margin-left: 15px;
  }
}

// Hacky fix for ngx-toastr.

#toast-container > div {
  opacity: 1;
}

select {
  option[disabled] {
    display: none;
  }
}

button[disabled] {
  cursor: not-allowed;
}

.sticky-top {
  top: 100px;
}

.confetti-canvas {
  display: inline-flex;
  position: absolute;
}

label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

#my_video {
  width: 100%;
  height: 100%;
  pointer-events: none;
  border: 2px solid #003946;
  -webkit-box-shadow: 0px 16px 31px -7px rgb(0 19 23 / 56%);
  box-shadow: 0px 16px 31px -7px rgb(0 19 23 / 56%);
}

.timestamptext {
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.qstyle {
  height: 50px;
  margin-left: 0px;
  background-color: #91c53d;
  table-layout: fixed;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
}

.qstemps {
  width: 23px;
  height: 32px;
  background: #b2e561;
  border: none;
  color: #fff;
  text-shadow: 1px 1px 2px rgb(0 0 0 / 66%);
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-left: 1px;
}

.qtime {
  text-align: center;
  width: 25px;
  float: left;
  font-size: 9px;
  background: #003946;
  color: #fff;
  margin-top: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 17px;
}
.qstemps:focus {
  outline: 1px dotted;
  outline: 1px solid #72a322;
}
.qstemps:hover {
  background: #84b733;
}

.qtr {
  float: left;
}
.qtd {
  width: 25px;
}

.qvideoslide {
  display: inline-block;
  width: 80% !important;
  max-width: 80%;
  margin-top: 2px;
}

#timer {
  font-size: 12px;
  float: right;
}
.passingspace {
  margin-top: 20px;
  font-size: 14px;
}

.qsmall {
  display: block;
  margin-top: 0.75rem;
  font-size: 79% !important;
  margin-bottom: 0.75rem;
  color: #8b8b8b;
  margin-left: 0.9rem;
}

.qpublishbtn {
  background-color: #2ac0d9;
  border-color: #65e9ff;
}
.qpublishbtn:hover {
  background-color: #003946;
  border-color: #65e9ff;
}
.qselect {
  font-weight: 600;
  margin-bottom: 30px;
}
.qselectbtn {
  margin-top: 30px;
}
.multinpt {
  width: 90%;
  display: initial;
}

.head1 {
  width: 80%;
  height: 39px;
  background: #e9ebed;
  margin-bottom: 16px;
  font-size: 12px;
  color: #787878;
  justify-content: flex-start;
  align-items: center;
}
.head2 {
  width: 20%;
  height: 39px;
  background: #eff0f1;
  margin-bottom: 16px;
  font-size: 12px;
  color: #787878;
  text-align: right;
}

.head3 {
  display: block;
  height: 38px;
  background: #e9ebed;
  margin-bottom: 16px;
  width: 10%;
  float: right;
  font-size: 12px;
  padding-top: 10px;
  padding-left: 3px;
  text-align: center;
  padding-right: 3px;
}
.checkcorrect {
  margin-top: 14px;
}

.deleteico {
  padding-top: 20px !important;
}

.qadd {
  width: 100%;
}

.pScormLoader {
  text-align: center;
  color: #3fc380;
}
/*
html {
  font-size: .7rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: .9rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.1rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.3rem;
  }
}
*/
div.ngx-file-drop__content {
  color: #003946 !important;
}
div.ngx-file-drop__drop-zone {
  border-color: #003946 !important;
}

ngx-monaco-editor > div.editor-container {
  min-height: 750px;
}

.hide-element {
  border: 0;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
